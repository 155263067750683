import { useNavigate } from "react-router-dom";
import React, { useState } from 'react';

import { Box, Button } from "@mui/material";
//
import {useIsMobile, BasePage } from "scandoc-react-components";
import upload from "../../images/upload.png";
import document_scan from "../../images/document_scan.png";
import customer_onboarding from "../../images/customer_onboarding.png";
import cc_scan from "../../images/cc_scan.png";
import styles from "./StartScanPage.module.css";
//
function Menu() {
	const isMobile = useIsMobile();
	const [selected, setSelected] = useState(null);
	const navigate = useNavigate();
	const tabs = [
		{ label: "Document Scan", imageSrc: document_scan },
		{ label: "Customer Onboarding", imageSrc: customer_onboarding },
		{ label: "Credit Card Scan", imageSrc: cc_scan },
		{ label: "Image Upload", imageSrc: upload },
	];
  
	const handleClick = (index) => {
	  setSelected(index);
	  switch (index) {
		case 0:
		  navigate('/document_scan');
		  break;
		case 1:
		  navigate('/customer_onboarding');
		  break;
		case 2:
		  navigate('/cc_scan');
		  break;
		case 3:
		  navigate('/upload');
		  break;
		default:
		  break;
	  }
	};
  
	return (
		<Box className={`${styles.container} ${isMobile ? styles.containerMobile : styles.containerDesktop}`}>
      {!isMobile ? (
        <Box className={styles.innerContainer}>
          {tabs.map((tab, index) => (
            <Button
              key={index}
              variant="contained"
              onClick={() => handleClick(index)}
              className={`${styles.button} ${selected === index ? styles.buttonSelected : styles.buttonDefault}`}
            >
              <Box className={styles.imageContainer}>
                <img
                  src={tab.imageSrc}
                  alt={tab.label}
                  className={styles.image}
                />
              </Box>
              <Box>{tab.label}</Box>
            </Button>
          ))}
        </Box>
      ) : (
        <Box className={styles.mobileContainer}>
          <Box className={styles.mobileRow}>
            {tabs.slice(0, 2).map((tab, index) => (
              <Button
                key={index}
                variant="contained"
                onClick={() => handleClick(index)}
                className={`${styles.mobileButton} ${selected === index ? styles.buttonSelected : styles.buttonDefault}`}
              >
                <Box className={styles.imageContainer}>
                  <img
                    src={tab.imageSrc}
                    alt={tab.label}
                    className={styles.image}
                  />
                </Box>
                <Box>{tab.label}</Box>
              </Button>
            ))}
          </Box>
          <Box className={styles.mobileRow}>
            {tabs.slice(2, 4).map((tab, index) => (
              <Button
                key={index + 2}
                variant="contained"
                onClick={() => handleClick(index + 2)}
                className={`${styles.mobileButton} ${selected === index + 2 ? styles.buttonSelected : styles.buttonDefault}`}
              >
                <Box className={styles.imageContainer}>
                  <img
                    src={tab.imageSrc}
                    alt={tab.label}
                    className={styles.image}
                  />
                </Box>
                <Box>{tab.label}</Box>
              </Button>
            ))}
          </Box>
        </Box>
      )}
    </Box>
  );
};

function StartScanPage() {
	return (
		<BasePage>
			<Menu />
		</BasePage>
	);
}

export default StartScanPage;
