import {useCallback, useContext, useMemo, useState} from "react";
//
import Menu from "../../components/Menu/Menu";
import {useIsMobile, NFCContext, BasePage, NewAnalysisSteps, DocumentExtractionResultDisplay} from "scandoc-react-components";
//
import styles from "./AnalysisPage.module.css";
import MainNavBar from "../../components/MainNavBar/MainNavBar";
//
function AnalysisPage({firstStep = NewAnalysisSteps.SCAN_DOCUMENT, includeFaceID = true, selectedTab=0}) {
    const isMobile = useIsMobile()
    const {isNFCReadAvailable} = useContext(NFCContext);
    const steps = useMemo(() => NewAnalysisSteps.getFullAnalysis(firstStep, {
        isNFCReadAvailable,
        includeFaceID
    }), [firstStep, isNFCReadAvailable, includeFaceID]);
    const [activeStep, setActiveStep] = useState(0);
    const [results, setResults] = useState();
    //
    const nextStep = useCallback(() => setActiveStep((s) => s + 1), []);
    const onStepComplete = useCallback(
        (results) => {
            nextStep();
            setResults(results);
        },
        [nextStep]
    );
    //
    const stepContent = useMemo(() => {
        if (activeStep === steps.length) {
            return (
                <>
                    <DocumentExtractionResultDisplay results={results}/>
                </>
            );
        }
        const AnalysisComponent = steps[activeStep].Component;
        return <AnalysisComponent results={results} onStepSkip={nextStep} onStepComplete={onStepComplete}/>;
    }, [steps, activeStep, nextStep, onStepComplete, results]);
    //
    return (
        <BasePage navCard={isMobile && <MainNavBar />}>
            <div className={styles.content}>
                {!isMobile && <Menu selectedTab={selectedTab} />}
                {stepContent}
            </div>
        </BasePage>
    );
}

export default AnalysisPage;
