import { useEffect, useState } from "react";
// Navigation imports
import { HashRouter, Navigate, Route, Routes } from "react-router-dom";
// Pages
import StartScanPage from "./pages/StartScanPage/StartScanPage";
import AnalysisPage from "./pages/AnalysisPage/AnalysisPage";
import FullAnalysisPage from "./pages/FullAnalysisPage/FullAnalysisPage";
import {NFCContext, NewAnalysisSteps, SCAN_DOC_AI_CONFIG } from "scandoc-react-components";
//
function App() {
	const [isNFCReadAvailable, setIsNFCReadAvailable] = useState(false);
	useEffect(() => {
		const mounted = { status: true };
		fetch(`${SCAN_DOC_AI_CONFIG.NFC_READER_SERVICE}`)
			.then((response) => mounted.status && setIsNFCReadAvailable(false))
			.catch(() => mounted.status && setIsNFCReadAvailable(false));
		return () => (mounted.status = false);
	}, []);
	//
	return (
		<NFCContext.Provider value={{ isNFCReadAvailable }}>
			<HashRouter>
				<Routes>
					<Route exact path="/" element={<StartScanPage />} />
					<Route
						exact
						path="/document_scan"
						element={<AnalysisPage key={NewAnalysisSteps.SCAN_DOCUMENT.id}
												   firstStep={NewAnalysisSteps.SCAN_DOCUMENT}
												   includeFaceID={false}
												   selectedTab={0}
						/>}
					/>
					<Route
						exact
						path="/customer_onboarding"
						element={<FullAnalysisPage key={NewAnalysisSteps.SCAN_DOCUMENT.id}
														firstStep={NewAnalysisSteps.SCAN_DOCUMENT}
														includeFaceID={true}
														selectedTab={1}
						/>}
					/>
					<Route
						exact
						path="/cc_scan"
						element={<FullAnalysisPage key={NewAnalysisSteps.SCAN_CREDIT_CARD.id}
												   firstStep={NewAnalysisSteps.SCAN_CREDIT_CARD}
												   includeFaceID={false}
												   selectedTab={2}
						/>}
					/>
					<Route
						exact
						path="/upload"
						element={<FullAnalysisPage key={NewAnalysisSteps.UPLOAD_DOCUMENT.id} firstStep={NewAnalysisSteps.UPLOAD_DOCUMENT} selectedTab={3} />}
					/>
					<Route exact path="*" element={<Navigate to="/" />} />
				</Routes>
			</HashRouter>
		</NFCContext.Provider>
	);
}

export default App;
