import {useState} from "react";
import { useNavigate, useLocation } from "react-router-dom";
//
import {Box, Button} from "@mui/material";
import styles from './Menu.module.css';

function Menu({selectedTab}) {
	const [selected, setSelected] = useState(selectedTab);
	const navigate = useNavigate();
	const location = useLocation();
	const tabs = ['Document Scan', 'Customer Onboarding', 'Credit Card Scan', 'Image Upload']
  
	const handleClick = (index) => {
		const currentPath = location.pathname;
		let newPath;
		setSelected(index);

		switch (index) {
			case 0:
				newPath = ('/document_scan');
				break;
			case 1:
				newPath = ('/customer_onboarding');
				break;
			case 2:
				newPath = ('/cc_scan');
				break;
			case 3:
				newPath = ('/upload');
				break;
			default:
				break;
		}

		if (currentPath === newPath) {
			window.location.reload();
		} else {
			navigate(newPath);
		}
	};
  
	return (
		<Box className={styles.container}>
			<Box className={styles.innerContainer}>
				{tabs.map((tab, index) => (
				<Button
					key={index}
					variant="contained"
					onClick={() => handleClick(index)}
					className={`${styles.button} ${selected === index ? styles.buttonSelected : styles.buttonDefault}`}
				>
					{tab}
				</Button>
				))}
			</Box>
		</Box>
	  );	  
};

export default Menu;