import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import styles from "./MainNavBar.module.css";

function MainNavBar() {
	  const navigate = useNavigate();
    const location = useLocation();
    const [isNavOpen, setIsNavOpen] = useState(true);

    const handleClick = (link) => {
      if (location.pathname === link) {
        setIsNavOpen(false);
      } else {
        navigate(link);
        setIsNavOpen(false);
      }
    };

    const menuTabs = [
        { label: 'Document Scan', link: '/document_scan' },
        { label: 'Customer Onboarding', link: '/customer_onboarding' },
        { label: 'Credit Card Scan', link: '/cc_scan' },
        { label: 'Image Upload', link: '/upload' }
      ];

    return (
        <div className={styles.navbar} style={{ display: isNavOpen ? 'block' : 'none' }}>
          {menuTabs.map((item, index) => (
            <div
              key={index}
              className={`${styles.navitem} ${location.pathname === item.link ? styles.active : ''}`}
              onClick={() => handleClick(item.link)}
            >
              {item.label}
            </div>
          ))}
        </div>
      );
}

export default MainNavBar;
