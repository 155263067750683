import {useCallback, useContext, useMemo, useState} from "react";
//
import {Step, StepLabel, Stepper, Typography} from "@mui/material";
import Menu from "../../components/Menu/Menu";
import {useIsMobile, NFCContext, BasePage, NewAnalysisSteps, NewExtractionResultDisplay} from "scandoc-react-components";
//
import styles from "./FullAnalysisPage.module.css";
import MainNavBar from "../../components/MainNavBar/MainNavBar";
//
function FullAnalysisPage({firstStep = NewAnalysisSteps.SCAN_DOCUMENT, includeFaceID = true, selectedTab=0}) {
    const isMobile = useIsMobile()
    const {isNFCReadAvailable} = useContext(NFCContext);
    const steps = useMemo(() => NewAnalysisSteps.getFullAnalysis(firstStep, {
        isNFCReadAvailable,
        includeFaceID
    }), [firstStep, isNFCReadAvailable, includeFaceID]);
    const [activeStep, setActiveStep] = useState(0);
    const [results, setResults] = useState();
    //
    const nextStep = useCallback(() => setActiveStep((s) => s + 1), []);
    const onStepComplete = useCallback(
        (results) => {
            nextStep();
            setResults(results);
        },
        [nextStep]
    );
    //
    const stepContent = useMemo(() => {
        if (activeStep >= steps.length) {
            return (
                <>
                    <NewExtractionResultDisplay results={results}/>
                </>
            );
        }
        const AnalysisComponent = steps[activeStep].Component;
        return <AnalysisComponent results={results} onStepSkip={nextStep} onStepComplete={onStepComplete}/>;
    }, [steps, activeStep, nextStep, onStepComplete, results]);
    //
    return (
        <BasePage navCard={isMobile && <MainNavBar />}>
            <div className={styles.content}>
                {!isMobile && <Menu selectedTab={selectedTab} />}
                {stepContent}
                {!isMobile && <Stepper
                    activeStep={activeStep}
                    sx={{
                        width: '50%',
                        margin: 'auto',
                        '& .MuiStepConnector-line': {
                            borderColor: '#BFC2C6',
                            borderRadius: '25px',
                            borderWidth: 5,
                            marginTop: 1,
                            marginLeft: 2,
                            marginRight: 2,
                            display:"flex",
                            justifyContent:"center",
                            alignItems:"center",
                        },
                        '& .MuiStepConnector-line.Mui-active': {
                            borderColor: '#5078BB',
                        },
                        '& .MuiStepConnector-line.Mui-completed': {
                        borderColor: '#5078BB',
                        },
                    }}
                    alternativeLabel
                >
                    {steps.map((step, index) => (
                        <Step key={step.id}>
                            <StepLabel optional={<Typography
                                variant="caption" sx={{color: activeStep >= index ? '#5078BB' : '#BFC2C6'}}>{step.optional ? "Optional" : "Mandatory"}</Typography>}
                                sx={{
                                    '& .MuiStepLabel-label': {
                                      color: '#BFC2C6',
                                    },
                                    '& .MuiStepLabel-label.Mui-active': {
                                        color: '#5078BB',
                                    },
                                      '& .MuiStepLabel-label.Mui-completed': {
                                        color: '#5078BB',
                                    },
                                    '& .MuiStepIcon-root': {
                                        color: '#BFC2C6',
                                        width: 42,
                                        height: 42,
                                    },
                                    '& .MuiStepIcon-root.Mui-active': {
                                        color: '#5078BB',
                                    },
                                    '& .MuiStepIcon-root.Mui-completed': {
                                        color: '#5078BB',
                                    },
                                }}
                            >
                                {step.name}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>}
            </div>
        </BasePage>
    );
}

export default FullAnalysisPage;
